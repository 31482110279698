<template>
    <div class="aboutContainer">
        <div class="container">
            <h1>關於我們</h1>
            <p>
                Big Box
                是台灣新興物流的供應商，結合了託運業、搬家業以及科技業，想要打造的是顛覆傳統的物流業，我們主打大型物件到府服務，致力於透過創新技術來振興傳統物流業。從以前的司機叫車配送服務，到現在將會改變，我們會將服務組合擴展為全方位物流，例如像是運送機車的同時也運送私人物品甚至大型物件。
            </p>
            <p>Big Box
                總部設於台中，以台中做為交通運輸出發，目前運送區域幾乎包含台灣西半部，
                未來希望可以服務到全台灣，讓偏鄉顧客也可以享受到便捷的服務。
                我們將個人和企業直接與司機夥伴連結，來滿足他們的即時貨運需求，
                並透過提供多樣化、客製化的貨運服務，重新定義日常生活中的配送體驗。</p>
            <p>
                我們的司機夥伴駕駛多種車型來適應不同的市場，幾乎任何大小的貨物都可以運送。同時，我們也提供快速和低成本的托運服務。
            </p>

            <p>
                我們致力透過更快捷和更具成本效益的方案，設法幫學生爭取更多的福利，透過到府收送的方式增加學生等消費者更多的便利性，並將產品運至目的地。提供多元化服務，目的是為消費者創造另一個新的消費平台，為整個社會帶來生活方式的變革，傳遞感動與分享，聯繫物流的兩端。
            </p>

        </div>
    </div>
</template>

<script>
    export default {
        name: "About"
    };
</script>
